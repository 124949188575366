<script>
import Service from '@/services';


const service = new Service();

export default {
    data() {
        return {
            menuDropdown: [],
            menuLink: [],
            openMenu: false,
            openSubMenu: null,
            menuLinks: [],
            openLangue: false
        };
    },
    async beforeMount() {
      this.populateMenu();
      this.populateMenuHorizental();
    },
    methods: {
        toggleMenu() {
            this.openMenu = !this.openMenu;
        },
        toggleSubMenu(menu){
          this.openSubMenu = this.openSubMenu === menu ? null : menu;
        },
        async populateMenu(){
          const menuData = await service.getMenuList('nav-2');
          const groupData = await service.getGroupList();

          for(let group of groupData) {
            let menuDropdown = menuData.filter(menu => (menu.url && menu.url.includes(group.slug)));
            this.menuDropdown.push({
              name: group.name,
              subMenu: menuDropdown.map(menu => ({
                name: menu.name,
                url: menu.url
              }))
            })
          }

          let menuLink = menuData.filter(menu => (menu.url && menu.type === 'link'));
          this.menuLink = menuLink.map(menu => ({
            name: menu.name,
            url: menu.url
          }))
        },
        clickMenu(name, link){
            localStorage.removeItem('childrenMenu');
            localStorage.removeItem('childrenLinkMenu')
            localStorage.setItem('parentMenu', name);
            localStorage.setItem('linkMenu', link)
        },
        linkClass(link) {
        const specialLinks = [
          "Utiliser de l'oxygène liquide pour l'oxygenothérapie ",
          "RESERVE FREELOX",
          "Nos services en oxygénothérapie",
          "Téléchargements Oxy",
          "PORTABLE FREELOX",
          "Oxygénotherapie"
        ];

        return specialLinks.includes(link)
          ? 'text-blue-4 hover:text-blue-2'
          : 'text-blue-2 hover:text-blue-4';
      },
      handleContactClick() {
      if (this.$route.path === '/accueil') {
        // Scroll to the contact section if on the homepage
        const contactSection = document.getElementById('contact');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // Redirect to the contact page if not on the homepage
        this.$router.push({ path: `/accueil` });
          setTimeout(() =>{
              const contactSection = document.getElementById('contact');
            if (contactSection) {
              contactSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 800)
      }
    },
    async populateMenuHorizental() {
      const menuData = await service.getMenuList('nav-1');
      this.menuLinks = menuData.map(menu => ({
        id: menu.id,
        name: menu.name,
        url: menu.url
      }))
    },
    reloadPage(url) {
      window.location.href = url; // Redirige et recharge complètement
    },
    toggleLanguage(){
      this.openLangue = !this.openLangue
    },
    },
    computed: {
        bgWhite() {
            return this.openMenu ? 'bg-white' : '';
        },
        isHomepage() {
          return this.$route.path === '/accueil';
        }
    },

};
</script>

<template>


<nav :class="isHomepage ? 'md:mt-20': 'md:mt-28'" class=" absolute md:fixed w-full z-10">
  <div :class="`w-full px-8 2xl:px-28 flex flex-wrap items-center justify-between mx-auto p-4 relative ${ bgWhite }`">
    <div v-if="openSubMenu && openMenu" class="hidden text-blue-1 left-1/3 bottom-4 z-1000 text-9xl font-bold place-items-end text-center">
      {{ openSubMenu }}
    </div>

    <RouterLink  class="flex items-center space-x-3 rtl:space-x-reverse">
    </RouterLink>

    <div :class="isHomepage ? 'md:mt-8': 'md:mt-24'" class=" flex absolute md:fixed right-3 mt-4 md:right-24 ">
      <a href="#" class="text-blue-2 block py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0 lg:hidden">
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z" clip-rule="evenodd"/>
          <path d="M7.2 8.809H4V19.5h3.2V8.809Z"/>
        </svg>
      </a>

      <button @click="toggleMenu" data-collapse-toggle="navbar-hamburger" type="button" class="inline-flex items-center justify-center p-2 w-10 h-10 text-sm rounded-lg focus:outline-none ring-2 ring-white focus:ring-blue-4 bg-gradient-to-tr from-blue-2 to-blue-4" aria-controls="navbar-hamburger" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5 text-white focus:text-blue-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>
    </div>

  
    <div :class="['w-full',  { 'hidden': !openMenu }]" id="navbar-hamburger">
      <ul class="flex flex-col font-medium mt-4 rounded-lg text-blue-2">

        <li class="me-4 lg:hidden">
            <a href="#" @click.prevent="handleContactClick" class="block py-2 px-3 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 font-bold" aria-current="page">Contactez-nous</a>
        </li>
        <li class="me-4 lg:hidden" v-for="link of menuLinks" :key="link.id">
            <a :href="link.url" @click="clickMenu(link.name, link.url)" class="block py-2 px-3 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 font-bold" aria-current="page">{{ link.name }}</a>
        </li>
        <li class="me-4 lg:hidden">
            <div>
            <a class=" flex flex-row py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0 font-bold text-xl cursor-pointer" @click="toggleLanguage">
              <img src="/fr.png" class=" w-6 h-auto mr-2"/> FR 
            </a>
            </div>
            <div id="dropdownNavbar"  v-show="openLangue" class="md:absolute md:right-36 z-10 mt-2 w-14 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ul class="py-1 text-sm font-semibold lg:grid lg:grid-flow-col grid-rows-3" aria-labelledby="dropdownLargeButton">
                  <li class="transition-all duration-150">
                    <a @click="reloadPage('/accueil')" class="text-blue-2 hover:text-blue-4 flex  py-1 cursor-pointer"><img src="/fr.png" class=" w-4 mr-2 ml-2"/> FR</a>
                  </li>
                  <li class="transition-all duration-150">
                    <a @click="reloadPage('/en/accueil')" class="text-blue-2 hover:text-blue-4 flex  py-1 cursor-pointer"><img src="/en.png" class=" w-4 mr-2 ml-2"/> EN</a>
                  </li>
                </ul>
          </div>
          </li>

        <!--  Dropdown -->
        <li class="lg:grid lg:grid-flow-col auto-cols-max gap-2" v-for="(dropdown, idx) in menuDropdown" :key="idx">
          <div>
            <a class="py-2 px-3 rounded hover:text-blue-4 text-center inline-flex items-center cursor-pointer" @click="toggleSubMenu(dropdown.name)">
              {{ dropdown.name }}
              <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1 9 4-4-4-4"/>
              </svg>
            </a>
          </div>
          <div id="dropdownNavbar"  v-show="openSubMenu === dropdown.name" class="col-start-2 z-1  font-normal divide-y w-full">
                <ul class="py-2 text-sm list-disc  font-semibold ml-6 lg:grid lg:grid-flow-col grid-rows-3" aria-labelledby="dropdownLargeButton">
                  <li :class="linkClass(link.name)" class="transition-all duration-150" v-for="(link, idx) in dropdown.subMenu" :key="idx">
                    <RouterLink :to="'/' + link.url" :class="linkClass(link.name)" class="text-blue-2 hover:text-blue-4 block pr-12 py-1 cursor-pointer">{{ link.name }}</RouterLink>
                  </li>
                </ul>
          </div>
        </li>

        <!--  Links -->
         <li class=" hover:text-blue-2" v-for="(link, idx) in menuLink" :key="idx">
          <RouterLink :to="link.url" class="block py-2 px-3">{{ link.name }}</RouterLink>
        </li>
      </ul>
    </div>
  </div>
</nav>

</template>


<style>
  .watermark {
    opacity: 0.25;
    font-size: 3em;
      z-index: 1000;
  }
</style>