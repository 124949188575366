<script>

export default {
    data() {
        return {
            isCapturingEnabled: false,
        };
    },
    mounted() {
        this.checkCapturingStatus();
    },
    methods: {
        checkCapturingStatus() {
            this.isCapturingEnabled = this.$posthog.has_opted_in_capturing();
        },
        acceptCookies() {
            this.$posthog.opt_in_capturing();
            this.$emit("hideBanner");
            localStorage.setItem('userOptedIn', true);
            localStorage.setItem('showModalCookies', 1);
            this.checkCapturingStatus();
        },
        declineCookies() {
            this.$posthog.opt_out_capturing();
            this.$emit("hideBanner");
            localStorage.setItem('userOptedIn', false);
            localStorage.setItem('showModalCookies', 1);
            this.checkCapturingStatus();
        }
    }
}

</script>

<template>
    <div>
        <div class="fixed top-3/4 right-20 cursor-pointer  bg-blue-3 p-5 rounded-full z-10">
            <img class="h-10" :src="`${publicPath}assets/img/logo_cr.png`" @click="toggleMenu" />
        </div>
    </div>
    <div>
        <div id="authentication-modal" tabindex="-1" aria-hidden="true"
            class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 grid grid-cols-1 gap-2 place-items-center w-full md:inset-0  max-h-full bg-white bg-opacity-55 backdrop-blur-sm transition-opacity ease-in duration-300">
            <div class="relative p-4 w-full md:w-6/12 max-h-full">
                <!-- Modal content -->
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <!-- Modal header -->
                    <div class="flex items-center justify-between p-2 md:p-2 rounded-t dark:border-gray-600">
                        <button @click="toggleMenu" type="button"
                            class="end-2.5 text-blue-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="authentication-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="p-4 md:p-5">
                        <div class="p-6 rounded-2xl h-full row-span-2">
                            <h2 class="font-semibold text-gray-800 ">🍪 Nous utilisons des cookies!</h2>

                            <p class="mt-4 text-sm text-gray-600 dark:text-gray-300">
                                Un cookie est un petit fichier de données, prenant la forme d'une suite d'informations, envoyé par le serveur internet de notre site Web au fichier cookie du navigateur situé sur le disque dur de votre appareil. 
                                Pour une durée déterminée, votre navigateur conservera ce cookie et le renverra au serveur du site Web lorsque vous vous y connecterez à nouveau. 
                                Les cookies peuvent avoir plusieurs usages : mémoriser vos identifiants afin de faciliter la connexion à votre compte, tracer vos sessions et votre navigation à des fin de statistiques, ou encore identifier vos centres d'intérêts pour vous proposer du contenu pertinent. 
                                Pour en savoir plus sur les cookies, veuillez consulter notre <router-link to="/politique-de-cookies" class=" text-blue-4">politique de cookies et de protection des données personnelles.</router-link>
                            </p>

                            <p class="mt-3 text-sm text-gray-600 dark:text-gray-300"> <span class=" text-blue-4 text-xl">Vos données personnelles sont traitées pour les finalités suivantes:</span> <br /><br />
                            Cookies strictement nécessaires au bon fonctionnement du site, Publicités et contenu personnalisés, mesure de performance des publicités et du contenu, études d’audience et développement de services, Stocker et/ou accéder à des informations sur un appareil</p>

                            <div class="grid grid-cols-2 gap-4 mt-4 shrink-0">
                                <button @click="acceptCookies"
                                    class=" text-xs text-blue-2 border border-blue-4 text-gray-800 hover:bg-gray-100 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                                    Accepter tout
                                </button>

                                <button @click="declineCookies"
                                    class=" text-xs text-blue-2 border border-blue-4 text-gray-800 hover:bg-gray-100 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                                    Refuser
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
