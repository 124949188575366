<script>
import Service from '@/services';
import LoginModal from '@/components/Modal/LoginModal.vue';
import { useRoute } from "vue-router"

const service = new Service();

export default {
  components: {
    LoginModal
  },
  data() {
    return {
      showLogin: false,
      showSearch: false,
      menuLinks: [],
      breadcrumbs: [{
          link: localStorage.getItem('linkMenu'),
          name: localStorage.getItem('parentMenu'),
        }],
      breadcrumbsChildren: [{
          path: localStorage.getItem('childrenLinkMenu'),
          name: localStorage.getItem('childrenMenu'),
        }],
      searchQuery: '',
      breadcrumbsList: [],
      storedBreadcrumbs: [],
      openLangue: false
    };
  },
  beforeMount() {
    this.populateMenu();
    this.$forceUpdate();
  },
  methods: {
    login() {
      this.showLogin = true;
    },
    search() {
      this.showSearch = !this.showSearch;       
    },
    closeModalLogin() {
      this.showLogin = false;
    },
    async populateMenu() {
      const menuData = await service.getMenuList('nav-1');
      this.menuLinks = menuData.map(menu => ({
        id: menu.id,
        name: menu.name,
        url: menu.url
      }))
    },
    clickMenu(name, link){
        localStorage.setItem('parentMenu', name);
        localStorage.setItem('linkMenu', link)
        localStorage.removeItem('childrenMenu');
        localStorage.removeItem('childrenLinkMenu')  
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          link: localStorage.getItem('linkMenu'),
          name: localStorage.getItem('parentMenu'),
        }
      ];
      this.breadcrumbsChildren = [
        {
          path: localStorage.getItem('childrenLinkMenu'),
          name: localStorage.getItem('childrenMenu'),
        }
      ];
    },
    getLinkUrl() {
      // Check if the current route is the homepage
      if (this.$route.path === '/') {
        return '#contact';
      } else {
        return '/contactez-nous';
      }
    },
    handleContactClick() {
      if (this.$route.path === '/accueil') {
        // Scroll to the contact section if on the homepage
        const contactSection = document.getElementById('contact');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // Redirect to the contact page if not on the homepage
        this.$router.push({ path: `/accueil` });
          setTimeout(() =>{
              const contactSection = document.getElementById('contact');
            if (contactSection) {
              contactSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 800)
      }
    },
    formatLink(link) {
    return link.startsWith('/') ? link : '/' + link;
    },
    handleSearch(){
      this.$router.push({  path: `/recherche`  });
      localStorage.setItem('searchQuery', this.searchQuery)
    },
    toggleLanguage(){
      this.openLangue = !this.openLangue
    },
    reloadPage(url) {
      window.location.href = url; // Redirige et recharge complètement
    },
  },
  computed: {
    isHomepage() {
      return this.$route.path === '/accueil';
    }
  },
  watch: {
    // Watch for route changes and update breadcrumbs
    '$route.meta.breadcrumb': {
      handler(newBreadcrumb) {
        if (newBreadcrumb) {
          if (JSON.stringify(this.breadcrumbsList) !== JSON.stringify(newBreadcrumb)) {
              this.breadcrumbsList.push(newBreadcrumb);
          }


          console.log('routes'+JSON.stringify(newBreadcrumb))

          if(this.$route.path.includes('nos-produits') === false){
              this.breadcrumbsChildren = []
              //this.breadcrumbs.push(newBreadcrumb);
              this.breadcrumbs= [
              {
                link:  newBreadcrumb[0].link,
                name: newBreadcrumb[0].name
              }
            ]
              localStorage.removeItem('childrenMenu');
              localStorage.removeItem('childrenLinkMenu');
              localStorage.setItem('parentMenu', newBreadcrumb[0].name);
              localStorage.setItem('parentLinkMenu', newBreadcrumb[0].link);
              
          }
          else if(this.$route.path.includes('nos-produits') === true && localStorage.getItem('childrenMenu')){
            this.breadcrumbs= [
              {
                link: localStorage.getItem('parentLinkMenu'),
                name: localStorage.getItem('parentMenu')
              }
            ]

            this.breadcrumbsChildren = [
              {
                path: newBreadcrumb[0].link,
                name: newBreadcrumb[0].name,
              }
            ]
              localStorage.setItem('parentMenu', newBreadcrumb[0].name);
              localStorage.setItem('parentLinkMenu', newBreadcrumb[0].link);
              localStorage.removeItem('childrenMenu');
              localStorage.removeItem('childrenLinkMenu');
          }
          else{
            this.breadcrumbsChildren = []
              //this.breadcrumbs.push(newBreadcrumb);
              this.breadcrumbs= [
              {
                link:  newBreadcrumb[0].link,
                name: newBreadcrumb[0].name
              }
            ]
              localStorage.removeItem('childrenMenu');
              localStorage.removeItem('childrenLinkMenu');
              localStorage.setItem('parentMenu', newBreadcrumb[0].name);
              localStorage.setItem('parentLinkMenu', newBreadcrumb[0].link);
          }
        }
      },
      immediate: true
    }
  },
  created() {
    const route = useRoute();
    // Set the initial breadcrumbs when component is created
      this.breadcrumbsList = route.meta.breadcrumb || [];
    
    /*if(!route.path.includes('nos-produits')){
      localStorage.removeItem('childrenMenu');
      localStorage.removeItem('childrenLinkMenu');
    }*/
  }
}

</script>

<template>
  <nav class="bg-blue-1 text-blue-2 fixed w-full z-20 hidden md:block">
    <div class="w-full px-8 2xl:pw-2 flex flex-wrap items-center justify-between mx-auto p-4">
      <ol class="inline-flex items-center space-x-3 md:space-x-3 rtl:space-x-reverse" v-if="isHomepage">
          <RouterLink to="/accueil" class="flex items-center space-x-3 rtl:space-x-reverse">
            <img :src="`${publicPath}assets/img/Logo_Cryopal.png`" class=" h-12" alt="Flowbite Logo" />
          </RouterLink>
      </ol>
      <ol class="inline-flex items-center space-x-3 md:space-x-3 rtl:space-x-reverse mt-4" v-if="!isHomepage"> 
        <li class="inline-flex items-center">
            <RouterLink to="/accueil" class="flex items-center space-x-3 rtl:space-x-reverse">
              <img :src="`${publicPath}assets/img/Logo_Cryopal.png`" class=" h-12" alt="Flowbite Logo" />
              </RouterLink>
        </li>
      </ol>
        
        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-6 rtl:space-x-reverse md:mt-0">
          <li class="me-4">
            <a href="#" @click.prevent="handleContactClick" class="block py-2 px-3 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 font-bold" aria-current="page">Contactez-nous</a>
          </li>
          <li class="me-4" v-for="link of menuLinks" :key="link.id">
            <a :href="link.url" @click="clickMenu(link.name, link.url)" class="block py-2 px-3 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 font-bold" aria-current="page">{{ link.name }}</a>
          </li>
          <li class=" flex items-center">
            <a href="#" class="block py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0">
              <svg @click="search()" class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
              </svg>
            </a>
            <div class="relative ml-3 transition-all duration-500" v-if="this.showSearch">
              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              </div>
              <input v-model="searchQuery" type="search" id="default-search"
                class="block w-full p-1 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                placeholder="Recherche" @keyup.enter="handleSearch" />
            </div>
          </li>
          <li>
            <a @click="login()" class="block cursor-pointer py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0">
              <svg class="w-6 h-6" aria-hidden="true" xmlns= "http://www.w3.org/2000/svg" width="24" height="24"
                fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd"
                  d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
                  clip-rule="evenodd" />
              </svg>
            </a>
          </li>
          <li>
            <div>
            <a class=" flex flex-row py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0 font-bold text-xl cursor-pointer" @click="toggleLanguage">
              <img src="/fr.png" class=" w-6 h-auto mr-2"/> FR 
            </a>
            </div>
            <div id="dropdownNavbar"  v-show="openLangue" class="absolute right-36 z-10 mt-2 w-14 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ul class="py-1 text-sm font-semibold lg:grid lg:grid-flow-col grid-rows-3" aria-labelledby="dropdownLargeButton">
                  <li class="transition-all duration-150">
                    <a @click="reloadPage('/accueil')" class="text-blue-2 hover:text-blue-4 flex  py-1 cursor-pointer"><img src="/fr.png" class=" w-4 mr-2 ml-2"/> FR</a>
                  </li>
                  <li class="transition-all duration-150">
                    <a @click="reloadPage('/en/accueil')" class="text-blue-2 hover:text-blue-4 flex  py-1 cursor-pointer"><img src="/en.png" class=" w-4 mr-2 ml-2"/> EN</a>
                  </li>
                </ul>
          </div>
          </li>
          <li>
            <a href="https://fr.linkedin.com/company/cryopal" class="block py-2 px-3 rounded hover:text-blue-4 md:border-0 md:p-0" target="_blank">
              <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd"
                  d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                  clip-rule="evenodd" />
                <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
              </svg>
            </a>
          </li>
        </ul>
    </div>
    <div>
       <ol class="inline-flex items-center space-x-3 md:space-x-3 rtl:space-x-reverse mb-2 ml-8" v-if="!isHomepage">
      <li class="inline-flex items-center" v-if="!isHomepage">
          <RouterLink :to="'/accueil'" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
            <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
            </svg>
            Accueil
          </RouterLink>
        </li>
        <li aria-current="page" v-for="(crumb, index) in breadcrumbs" :key="index">
          <div class="flex items-center" v-if="crumb.name">
            <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <RouterLink :to="formatLink(crumb.link)" @click="clickMenu(crumb.name, crumb.link)" class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">{{ crumb.name }}</RouterLink>
          </div>
        </li>
        <li aria-current="page" v-for="(crumb, index) in breadcrumbsChildren" :key="index">
          <div class="flex items-center" v-if="crumb.name">
            <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <p  class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">{{ crumb.name }}</p>
          </div>
        </li>
        </ol>
    </div>
      
  </nav>

  <LoginModal v-if="this.showLogin" @close="closeModalLogin()" />

</template>

<style>
.header {
  background-color: rgba(116, 143, 201, 0.5);
}
</style>