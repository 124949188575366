<script>
import FooterNavbar from '@/components/Navbar/FooterNavbar.vue';
import CookiesModal from '@/components/Modal/CookiesModal.vue';
import '@/assets/css/main.css'
import '@/index.css'


export default {
  data: function() {
    return {
      showBanner: !(this.$posthog.has_opted_in_capturing() || this.$posthog.has_opted_in_capturing())
    }
  },
   mounted() {
        if (localStorage.getItem('showModalCookies')) {
            this.showBanner = false;
        }
  },
  computed: {
    isHomepage() {
      return this.$route.path === '/';
    }
  },
  components: {
    FooterNavbar,
    CookiesModal
  }
};
</script>

<template>
    <RouterView :key="$route.fullPath"></RouterView>
    <!--<FloatingButton v-if="!isHomepage" />-->
    <CookiesModal v-if="showBanner"  @hideBanner="showBanner = false" />
  <FooterNavbar />

</template>
