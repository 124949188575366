<script>
import AsideNavbar from '@/components/Navbar/AsideNavbar.vue';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      title: '',
      image: `${this.publicPath}assets/img/formation-bg.png`,
      video: `${this.publicPath}assets/img/accueil.mp4`,
      homepage: false
    };
  },
  props: {
    titleProp: {
      type: String,
      required: false,
      default: () => null
    },
    imageProp: {
      type: String,
      required: false,
      default: () => null
    },
    params: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  components: {
    AsideNavbar
  },
  computed: {

    classBanner(){
      
     const route = useRoute();
      
      // Array of specific URLs
      const specificPaths = ['/qui-sommes-nous', 'vos-besoins', 'carriere-a-cryopal', 'faq', 'voir-nos-produits', 'nos-services', 'telechargements', 'brochures-commerciales-oxy', 'cgv', 'recherche', 'contactez-nous', 'postulez-en-ligne-cryopal', 'mentions-legales', 'plan-du-site'];

      const accueil = ['/accueil']

      const urlContainsWord = specificPaths.some(word => route.path.includes(word));
      const urlAccueil = accueil.some(word => route.path.includes(word));

        if (urlContainsWord) {
          return 'h-60vh bg-no-repeat bg-cover bg-center';
        }
        else if(urlAccueil){
            return 'h-35vh md:h-81 bg-no-repeat bg-cover bg-center';
        }
        return 'h-60vh md:h-81 bg-no-repeat bg-cover bg-center'; // Default classes
    }
      
  },
  mounted() {
    if (this.titleProp && this.imageProp) {
      this.title = this.titleProp;
      this.image = this.imageProp;
    } else {
      if (this.params) {
        this.func.populateParams(this, this.params);
        this.func.checkImageExists(`${this.apiUrl}/media/${this.image}`, (exists) => {
          this.image = exists ? `${this.apiUrl}/media/${this.image}` : `${this.publicPath}assets/img/formation-bg.png`;
        });
      }
    }

    const route = useRoute();
      
      const home = ['/accueil'];

      const isHomepage = home.some(word => route.path.includes(word));

        if (isHomepage) {
            this.homepage = true;
        }
  }
}

</script>

<template>
  <div :class="classBanner" :style="`background-image: url('${image}')`">
    <AsideNavbar />
    <video class=" h-full w-full md:object-cover object-fill " v-if="homepage" autoplay loop muted :poster="`${publicPath}assets/img/bg2.png`" >
      <source :src="`${publicPath}assets/img/accueil.mp4`" type="video/mp4">
    </video>
    <div class="h-full text-white px-10  md:w-60-percent">
      <div class="pt-60 md:ml-16 md:pt-48">
        <div class="font-bold uppercase 2xl:text-6xl text-4xl flex">
          <div>
            <span class="h-60 mb-10">{{ title }}</span>
            <div v-if="title != ''" class=" bg-gradient-to-r from-blue-2 to-blue-4 p-1 w-32 mt-4 mb-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>