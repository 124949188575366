<script>

export default {
    data() {
        return {
            dataLength: 3,
            publicPath: process.env.BASE_URL,
            
        }
    },
}
</script>

<template>
    <div id="contact" class="w-full grid md:grid-cols-2 gap-4 mb-4 pt-20 pb-20 bg-cover content-center font-semibold " :style="`background-image: url('${publicPath}assets/img/bg3.png')`">
        <div class="block md:flex w-full items-center justify-center rounded h-full0">
            <p class=" text-5xl md:text-7xl text-blue-2 font-bold text-center">
                Contactez-<span class=" text-blue-4">nous</span> !
            </p>
        </div>
        <div class="justify-center rounded0 gap-1 items-center h-full">
            <div class="inline-flex items-center">
            
            </div>
            <div class="grid md:grid-cols-1 p-6 rounded-2xl h-full row-span-2">
                <!--<div class="items-center justify-center p-5 text-center bg-opacity-00 lg:border-r-2 border-blue-2">
                    <blockquote class="max-w-2xl mx-auto mb-4 text-blue-2-500 lg:mb-8">
                        <h3 class="text-lg text-blue-2">Par Téléphone</h3>
                        <p class="my-4 text-blue-2 flex justify-between"><img :src="`${publicPath}assets/img/phone.png`" class=" h-5 mr-3"/> <span> De Lundi au vendredi <br /> de 09h à 18h </span> </p>
                    </blockquote>
                    <figcaption class="flex justify-between ">
                        <div class="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                            <a href="" class="text-blue-4 font-bold text-sm underline decoration-2 underline-offset-2"></a>
                        </div>
                    </figcaption>    
                </div>-->
                <div class="items-center justify-center p-5 text-center bg-opacity-00 flex flex-col">
                    <div class=" t-tooltip formulaire w-80 mb-10"> 
                        <RouterLink to="/contactez-nous" class=" block w-full text-2xl bg-blue-2 p-4 rounded-md text-white hover:bg-white hover:text-blue-2 hover:border transition-all duration-300">Formulaire de contact</RouterLink>
                        <p class="hidden t-box text-left grad p-10 bg-white text-blue-2 rounded-xl absolute w-80"> Formulaire de contact pour tout type de demande </p>
                    </div>
                    
                    <div class=" t-tooltip prestation w-80 mb-10">
                        <a href="https://public.teepee.fr/#/cryopal/Demande%20d'intervention" target="blank" class=" block w-full text-2xl bg-blue-2 p-4 rounded-md text-white hover:bg-white hover:text-blue-2 hover:border transition-all duration-300">Demande de prestation</a>
                        <p class="hidden t-box text-left grad p-10 bg-white text-blue-2 rounded-xl absolute w-80">
                            Permet une demande de mise en service, une demande de maintenance préventive ou une demande de qualification IQ-OQ.
                        </p>
                    </div>
                   
                     <div class=" t-tooltip reclamation w-80 mb-5">
                        <a href="https://public.teepee.fr/#/cryopal/Demande%20de%20r%C3%A9clamation" target="blank" class=" block w-full text-2xl bg-blue-2 p-4 rounded-md text-white hover:bg-white hover:text-blue-2 hover:border transition-all duration-300">Formuler une réclamation</a> 
                        <p class="hidden t-box text-left grad p-10 bg-white text-blue-2 rounded-xl absolute w-80"> 
                            Permet une demande d’Intervention suite à une panne, un retour produit à Cryopal pour échange ou réparation, un problème de livraison (erreur sur la quantité ou sur le produit), une demande d’avoir suite à une non-conformité.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<style>
 .t-tooltip.formulaire:hover .t-box,  .t-tooltip.prestation:hover .t-box,  .t-tooltip.reclamation:hover .t-box{
display: block;
 }
</style>